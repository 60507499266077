<template>
    <div class="typeArea">
        <div v-if="screenState" style="color: #d6ab7e;;font-size:30px;padding:56px 0 20px 0;border-bottom:1px solid #ddd;width: 100%;cursor: pointer;">
            <router-link to="/news/newsList" style="color: #999;">新闻资讯</router-link> &gt;&nbsp;{{title}}
        </div>
        <div style="font-size:22px;color:#333;font-weight:600;width: 100%;margin-top:40px;">{{infoData.title}}</div>
        <div style="font-size:14px;color:#666;margin-top:20px;width:100%">{{infoData.createTime}}</div>
        <div v-html="infoData.content" class="context" style="width:100%;margin-top:50px;padding-bottom:39px;border-bottom:1px solid #eee"></div>
        <div v-if="screenState" style="display:flex;justify-content:space-between;width:100%;font-size:14px;color:#999;margin-top:20px">
            <span @click="changeNews(preId)">{{pre}}</span>
            <span @click="changeNews(nextId)">{{next}}</span>
        </div>
        <div v-if="screenState" style="display:flex;justify-content:space-between;width:100%;text-align:right;font-size:14px;color:#333;margin-top:17px;cursor:pointer;">
            <span @click="changeNews(preId)">{{preText}}</span>
            <span @click="changeNews(nextId)">{{nextText}}</span>
        </div>
    </div>
</template>
<script>
import {getArticle,getCategory} from "../../api/api"
import merge from "webpack-merge"
export default {
    data(){
        return{
            infoData : {},
            query : {},
            pre : "上一篇",
            next : "下一篇",
            preText : "",
            nextText : "",
            preId : 0,
            nextId : 0,
            title:'勐海新闻',
            screenState:false
        }
    },
    mounted(){
		
	},
    created(){
        // 初始化获取浏览器窗口大小
		let screenWidth = document.body.clientWidth;
		// 根据浏览器窗口大小判断是否显示页面内的部分内容
		if(screenWidth<1200){
			this.screenState=false;
		}else{
			this.screenState=true;
		}

        this.getNewsList();
        if(this.$route.query.type == '1'){
            this.title = '勐海资讯'
        }else if(this.$route.query.type == '2'){
            this.title = '普洱茶新闻'
        }else{
            this.title = '政府政策'
        }
    },
    watch:{
        $route(to,from){
            this.getNewsList();
        }
    },
    methods:{
        getNewsList(){
            this.pre = "上一篇",
            this.next = "下一篇",
            this.preText = "",
            this.nextText = "",
            this.preId = 0,
            this.nextId = 0
            this.query = this.$route.query
            // 获取文章详情
            getArticle(this.$route.query.id,(res) => {
                this.infoData = res.data
            })

            let that = this;
            let params = {
                pageNum : 1,
                pageSize : 9999
            }
            getCategory(this.query.type,params,(res) => {
                for(let i=0 ;i<res.data.length;i++){
                    if(res.data[i].id == that.query.id){
                        // 文章是第一篇 且文章不是最后一篇
                        if(i == 0 && (i+1) != res.data.length){
                            that.pre = "没有上一篇啦！",
                            that.nextText = res.data[i +1].title
                            that.nextId = res.data[i +1].id
                        // 文章不是第一篇 且文章不是最后一篇
                        }else if(i != 0 && (i+1) != res.data.length){
                            that.nextText = res.data[i+1].title
                            that.preText = res.data[i-1].title
                            that.nextId = res.data[i +1].id
                            that.preId = res.data[i -1].id
                        // 文章是最后一篇
                        }else{
                            that.next = "没有下一篇啦！"
                            that.preText = res.data[i-1].title
                            that.preId = res.data[i-1].id
                        }
                    }
                }
            })
        },
        changeNews(id){
            if(id != 0){
                this.$router.push({
                    query:merge(this.$route.query,{'id':id})
                })
            }
        }
    }
}
</script>
<style scoped>
span{
    cursor: pointer;
}

@media only screen and (min-width:1200px){
    .context >>> p,
    .context >>> span{
        line-height: 2.5em !important;
        text-indent: 2em !important;
        font-size: 16px !important;
    }
    .context >>> img{
        display:block;
        transform: translateX(25%);
        margin:20px 0;
    }
}

/* 移动端样式 */
@media only screen and (max-width:1199px){
    .context >>> p{
        line-height: 2em;
        text-indent: 0 !important;
    }
    .context >>> img{
        margin-top: 10px;
    }
    .typeArea{
        width:100%;
        padding:0 20px;
        box-sizing: border-box;
    }
    .typeArea >>> img{
        width:100%;
        height:auto;
    }
    .typeArea>div:first-child{
        font-size: 18px !important;
        line-height:30px;
        margin-top:24px !important;
    }
    .typeArea>div:nth-child(2){
        font-size: 12px !important;
        line-height:20px;
        border-bottom:1px solid #eee;
        margin-top: 7px !important;
        padding-bottom: 16px;
    }
    .typeArea>div:nth-child(3){
        margin-top: 23px !important;
    }
    .typeArea>div:nth-child(3)>>>P{
        font-size:14px;
        color:#666;
    }
}
</style>